import React from 'react';

import { withLDProvider } from 'launchdarkly-react-client-sdk';
import './src/styles/breakpoints.css';
import './src/styles/font.css';
import './src/styles/global.css';
import './src/styles/spacing.css';
import './src/styles/input.css';
import './src/styles/carl-typography.css';
import './src/styles/main.scss';

import { initializeLoggingCookie, LOGGING_TYPE } from './logging';
import { SanityPreviewConnectorProvider } from './src/components/preview/sanityPreviewConnector';
import { PreviewProvider } from './src/context/PreviewContext';
import { getCookie } from './src/utils/cookie-helpers';

export const onInitialClientRender = async () => {
  // used to strip away all styles and stylesheets that Marketo renders
  // would be nice to have a better way to do this
  function destyleMktoForm(mktoForm, moreStyles) {
    var formEl = mktoForm.getFormElem()[0],
      arrayFrom = Function.prototype.call.bind(Array.prototype.slice);
    var styledEls = arrayFrom(formEl.querySelectorAll('[style]')).concat(formEl);

    styledEls.forEach(function (el) {
      el.removeAttribute('style');
    });

    var styleSheets = arrayFrom(document.styleSheets);

    styleSheets.forEach(ss => {
      if (
        ['mktoForms2BaseStyle', 'mktoForms2ThemeStyle'].indexOf(ss.ownerNode.id) !== -1 ||
        formEl.contains(ss.ownerNode)
      ) {
        ss.disabled = true;
      }
    });

    if (!moreStyles) {
      formEl.setAttribute('data-styles-ready', 'true');
    }
  }

  window.MktoForms2.whenRendered(function (form) {
    destyleMktoForm(form);
  });


  // Check for logging ids in cookies and initialize them if they aren't found.
  // We use 2 different logging ids:
  // - "elid" -> event logging id for persistent logging
  // - "asid" -> anonymous session id for session logging
  initializeLoggingCookie(LOGGING_TYPE.EVENT);
  initializeLoggingCookie(LOGGING_TYPE.SESSION);
};

export const wrapRootElement = ({ element }) => {
  const App = () => (
    <PreviewProvider>
      <SanityPreviewConnectorProvider>
        {element}
      </SanityPreviewConnectorProvider>
    </PreviewProvider>
  );

  // wrap app with LaunchDarkly provider
  const LDApp = withLDProvider({
    clientSideID: process.env.GATSBY_LD_CLIENT_ID,
    context: {
      key: getCookie('elid') || 'anonymous',
      kind: 'user'
    },
    options: {
      streaming: false
    }
  })(App);

  return <LDApp />;
};

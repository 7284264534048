import React, { createContext, useState } from 'react';

import { PreviewIndicator } from '../components/preview/PreviewIndicator';

// Set up the default context object
const defaultContext = {
  activePreview: '',
  setActivePreview: '',
  previewContextData: '',
  setPreviewContextData: '',
  previewIsLoading: '',
  setPreviewIsLoading: '',
  flagValue: '',
  setFlagValue: '',
  flagName: '',
  setFlagName: '',
  abTestActive: '',
  setAbTestActive: '',
};

// Create the PreviewContext using createContext
const PreviewContext = createContext(defaultContext);

const PreviewProvider = ({ children }) => {
  // state
  const [activePreview, setActivePreview] = useState(false);
  const [previewIsLoading, setPreviewIsLoading] = useState(false);
  const [previewContextData, setPreviewContextData] = useState({
    // Adapt if you have another default dataset name
    previewContextDataset: 'production'
  });
  const [previewValidationData, setPreviewValidationData] = useState([]);
  const [isNewUnpublishedDoc, setIsNewUnpublishedDoc] = useState(false);
  const [flagValue, setFlagValue] = useState('');
  const [flagName, setFlagName] = useState('');
  const [abTestActive, setAbTestActive] = useState(false);

  // Render the PreviewProvider component
  return (
    <PreviewContext.Provider
      value={{
        activePreview,
        setActivePreview,
        previewContextData,
        setPreviewContextData,
        previewIsLoading,
        setPreviewIsLoading,
        previewValidationData,
        setPreviewValidationData,
        isNewUnpublishedDoc,
        setIsNewUnpublishedDoc,
        flagValue,
        setFlagValue,
        flagName,
        setFlagName,
        abTestActive,
        setAbTestActive,
      }}
    >
      {children}
      {/* Render the PreviewIndicator component when activePreview is true */}
      {activePreview && <PreviewIndicator isLoading={previewIsLoading} />}
    </PreviewContext.Provider>
  );
};

export { PreviewContext, PreviewProvider };

import React from 'react';

import * as styles from './preview-indicator.module.css';

export function PreviewIndicator({ isLoading = false }) {
  return (
    <div className={styles.previewContainer}>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <div className={styles.textContainer}>
            <p>Preview is loading...</p>
          </div>
        </div>
      )}
      {!isLoading && (
        <div className={styles.loadedContainer}>
          <p>Preview up-to-date</p>
        </div>
      )}
    </div>
  );
}

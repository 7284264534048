import { createClient } from '@sanity/client';

const projectId = process.env.GATSBY_SANITY_PROJECT_ID ?? '0dljr336';
const envDataset = process.env.GATSBY_SANITY_DATASET ?? 'staging';
const apiVersion = '2024-12-28';

export function getSanityPreviewClient({
	perspective = 'previewDrafts',
	previewDataset = envDataset,
}) {
	const client = createClient({
		projectId,
		dataset: previewDataset,
		apiVersion,
		useCdn: false, // to ensure data is fresh,
		token: process.env.GATSBY_SANITY_READ_TOKEN,
		perspective,
		ignoreBrowserTokenWarning: true,
	});

	return client;
}

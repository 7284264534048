export function getCookie(name: string): string | undefined {
	if (typeof window !== 'undefined') {
		let cookieEnabled = navigator.cookieEnabled;

		if (!cookieEnabled) {
			document.cookie = 'testcookie';
			cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
		}

		if (cookieEnabled) {
			return document.cookie
				.split('; ')
				.find((row) => row.startsWith(`${name}=`))
				?.split('=')[1];
		}
	}

	return;
}

export function setCookie(
	name: string,
	value: string,
	expires: Date | null,
	domain: string,
	path = '/'
) {
	const expirationString = expires
		? ` expires=${expires.toUTCString()};`
		: '';

	if (typeof window !== 'undefined') {
		let cookieEnabled = navigator.cookieEnabled;

		if (!cookieEnabled) {
			document.cookie = 'testcookie';
			cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
		}

		if (cookieEnabled) {
			const newCookie = `${name}=${value};${expirationString} domain=${domain}; path=${path}`;
			return (document.cookie = newCookie);
		}
	}
}

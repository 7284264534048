import React, { useContext } from 'react';

import { LiveQueryProvider } from '@sanity/preview-kit';

import { PreviewContext } from '../../context/PreviewContext';
import { getSanityPreviewClient } from '../../utils/sanity';

export function SanityPreviewConnectorProvider({ children }) {
  const { activePreview, previewContextData } = useContext(PreviewContext);

  if (!activePreview) {
    // Return the regular children with no draft documents
    return <>{children}</>;
  }

  const client = getSanityPreviewClient(
    previewContextData?.previewDataset
  );

  // Preview mode enabled
  return <LiveQueryProvider client={client}>{children}</LiveQueryProvider>;
}

import { getCookie, setCookie } from './src/utils/cookie-helpers';

export const LOGGING_TYPE = {
  EVENT: 'elid', // event logging id for persistent logging
  SESSION: 'asid', // anonymous session id for session logging
};

/**
 * Initialize a single cooking with a logging uuid.
 *
 * @param {LOGGING_TYPE} type - The type of logging id to initialize.
 */

export function initializeLoggingCookie(type) {
  // we must set the domain without a subdomain so we can share this cookie between marketing and secure
  const domain = window.location.hostname;

  // set the expiration to follow how we expire event/session cookies in Cove
  // if we are setting a session cookie, we set it to expire 30 minutes from now
  const expires =
    type === LOGGING_TYPE.SESSION ? new Date(Date.now() + 30 * 60 * 1000) : null;

  let loggingCookie = getCookie(type);
  if (!loggingCookie) {
    try {
      loggingCookie = window.crypto.randomUUID();
      setCookie(type, loggingCookie, expires, domain);
    } catch (err) {
      // if we can't generate a uuid, we just don't set a cookie
      // this should only apply to browsers that don't support crypto like IE
    }
  }
}
